/* eslint-disable */
import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout
      headerStyles="text-4xl font-bold leading-none tracking-widest text-green-600 md:text-5xl lg:text-6xl"
      headerTitle="Blog"
      mainLayout=""
      location={location}
      title="Blog"
    >
      <SEO title="Blog" />
      <section className="relative max-w-lg px-4 py-16 mx-auto bg-white md:py-24 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div>
          <h2 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Recent articles
          </h2>
          <p className="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
            Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat
            massa dictumst amet. Sapien tortor lacus arcu.
          </p>
        </div>
        <div className="grid gap-16 pt-10 mt-6 border-t-2 border-gray-100 lg:grid-cols-2 lg:col-gap-5 lg:gap-y-12">
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            return (
              <article
                key={node.fields.slug}
                itemScope
                itemType="http://schema.org/Article"
              >
                <p className="text-sm leading-5 text-gray-500">
                  <time dateTime={node.frontmatter.date}>
                    {node.frontmatter.date}
                  </time>
                </p>
                <Link to={node.fields.slug} className="block">
                  <h3 className="mt-2 text-xl font-semibold leading-7 text-gray-900">
                    {title}
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                    itemProp="description"
                    className="mt-3 text-base leading-6 text-gray-500"
                  ></p>
                </Link>
                <div className="mt-3">
                  <Link
                    to={node.fields.slug}
                    className="text-base font-semibold leading-6 text-green-600 transition duration-150 ease-in-out hover:text-green-500"
                  >
                    Read full story
                  </Link>
                </div>
              </article>
            );
          })}
        </div>
      </section>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
